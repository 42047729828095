import { NextSeo } from 'next-seo'
import type { NextSeoProps } from 'next-seo'
import { useRouter } from 'next/router'
import { defaultLocale } from '@abcam-web/shared/config'

type PageSeoProps = {
  hreflangs?: readonly {
    hrefLang: string
    href: string
  }[]
  canonicalUrl?: string
  isNoIndexNoFollowNoArchive?: boolean
} & Omit<NextSeoProps, 'nofollow' | 'noindex' | 'robotsProps'>

export const PageSeo = ({
  hreflangs,
  canonicalUrl,
  isNoIndexNoFollowNoArchive = false,
  ...otherProps
}: PageSeoProps) => {
  const props: NextSeoProps = {}
  const { locale } = useRouter()
  // This default false value should not be forced by us, without it the library misbehaves
  props.noindex = false

  // Only the main English locale should be indexed, do not index Chinese and Japanese locales at all
  if (locale !== defaultLocale) {
    isNoIndexNoFollowNoArchive = true
  }

  // Override if the page is noindex, nofollow, noarchive
  if (isNoIndexNoFollowNoArchive) {
    props.nofollow = true
    props.noindex = true
    props.robotsProps = { noarchive: true }
  }

  return (
    <NextSeo
      canonical={canonicalUrl}
      languageAlternates={hreflangs}
      {...props}
      {...otherProps}
    />
  )
}
