import { removeHash } from './removeHash'
import { removeQueryString } from './removeQueryString'
import { removeTrailingSlash } from './removeTrailingSlash'
import { BASE_URL_BY_DOMAIN } from '@abcam-web/auth-shared/constants/base-url'
import getConfig from 'next/config'
import type { STAGE } from '@abcam-web/auth-shared/constants/stage-type'

export const buildCanonicalUrl = (
  locale: string | undefined = '',
  resolvedUrl: string,
  queryStringRegex?: RegExp
) => {
  const stage = getConfig().publicRuntimeConfig?.STAGE as STAGE
  const canonicalPath = queryStringRegex && queryStringRegex.test(resolvedUrl) ?
    removeTrailingSlash(removeHash(resolvedUrl)) :
    removeTrailingSlash(removeHash(removeQueryString(resolvedUrl)))
  const origin = BASE_URL_BY_DOMAIN[stage]
  return `${origin}${locale}${canonicalPath}`
}
